import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersReportsPageComponent } from './users/users-page.component';

export const routes: Routes = [
  {
    path: 'users',
    component: UsersReportsPageComponent,
    data: {
      caption: 'Users',
    },
  },
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
