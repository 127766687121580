import { Component, Input } from '@angular/core';
import { FDN } from '@intersystems/isc-form';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/core/interfaces/user';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { Sort } from '@angular/material/sort';

const productDescs = {
  '83lxl58o1miosrihkx2p0v0wu': 'FHIR Server',
  asfw2iij7m0p33qtg5ks0ycrx: 'FHIR Transformation Services',
};

const productCodes = {
  fhiraas: '83lxl58o1miosrihkx2p0v0wu',
  hmts: 'asfw2iij7m0p33qtg5ks0ycrx',
};

@Component({
  selector: 'app-users-reports-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent {
  _users$ = new BehaviorSubject<User[]>(null);
  @Input() set users(val) {
    this._users$.next(val);
  }
  get users() {
    return this._users$.value;
  }

  filter$ = new BehaviorSubject<any>({
    hmts: false,
    fhiraas: false,
  });
  get filter() {
    return this.filter$.value;
  }
  set filter(val) {
    this.filter$.next(val);
  }

  filteredUsers$ = combineLatest([this._users$, this.filter$]).pipe(
    map(([users, filter]) =>
      users?.filter(item => {
        let res = true;
        if (filter?.hmts && !item.subscriptions[productCodes.hmts]) res = false;
        if (filter?.fhiraas && !item.subscriptions[productCodes.fhiraas]) res = false;
        if (filter?.created?.createdFrom && new Date(item.created_at) < new Date(filter.created.createdFrom))
          res = false;
        if (filter?.created?.createdTo && new Date(item.created_at) > new Date(filter.created.createdTo)) res = false;
        return res;
      }),
    ),
  );

  constructor(public tableService: TableService) {}

  tableConfig: TableConfig = {
    key: 'users-table',
    header: {
      title: 'Users Report',
    },
    noDataMessage: 'No users',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('username'),
    sort: {
      sortFunction: (event: Sort, data: any) => {
        return data.sort((a: any, b: any) => {
          const isAsc = event.direction === 'asc';
          switch (event.active) {
            case 'username':
              return this.tableService.compareAlphaNumeric(a.username, b.username, isAsc);
            case 'fullName':
              return this.tableService.compareAlphaNumeric(a.first_name, b.first_name, isAsc);
            case 'email':
              return this.tableService.compareAlphaNumeric(a.email, b.email, isAsc);
            case 'created_at':
              return this.tableService.compareAlphaNumeric(a.created_at, b.created_at, isAsc);
            default:
              return 0;
          }
        });
      },
    },
    columns: [
      {
        id: 'username',
        key: 'username',
        title: 'Username',
        sortable: true,
        cellDisplay: {
          model: 'username',
        },
      },
      {
        id: 'fullName',
        key: 'fullName',
        title: 'Full Name',
        sortable: true,
        cellDisplay: {
          getDisplay: (row: User) => row.first_name + ' ' + row.last_name,
        },
      },
      {
        id: 'email',
        key: 'email',
        title: 'Email',
        sortable: true,
        cellDisplay: {
          model: 'email',
        },
      },
      {
        id: 'created_at',
        key: 'created_at',
        title: 'Created At',
        sortable: true,
        cellDisplay: {
          model: 'created_at',
        },
      },
      {
        id: 'subscriptions',
        key: 'subscriptions',
        title: 'Subscriptions',
        cellDisplay: {
          getDisplay: (row: User) =>
            Object.keys(row.subscriptions)
              .map(item => productDescs[item])
              .join(','),
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  };

  filterFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        hideExpression: model => {
          this.filter$.next(model);
          return false;
        },
        fields: [
          {
            key: 'created',
            className: 'createdGroup',
            fieldGroup: [
              {
                id: 'createdFrom',
                key: 'createdFrom',
                type: 'date-picker',
                templateOptions: {
                  label: 'Created From',
                },
              },
              {
                id: 'createdTo',
                key: 'createdTo',
                type: 'date-picker',
                templateOptions: {
                  label: 'To',
                },
              },
            ],
          },
          {
            id: 'hmts',
            key: 'hmts',
            type: 'checkbox',
            templateOptions: {
              label: 'FHIR TS subscription',
            },
          },
          {
            id: 'fhiraas',
            key: 'fhiraas',
            type: 'checkbox',
            templateOptions: {
              label: 'FHIR Server subscription',
            },
          },
        ],
      },
    ],
  };
}
