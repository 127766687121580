import { Routes } from '@angular/router';
import { routes as cloudservicesRoutes } from './cloud-services/cloud-services-routing.module';
import { routes as deploymentRoutes } from './deployments/deployments-routing.module';
import { routes as tenantsRoutes } from './tenants/tenants-routing.module';
import { routes as settingsRoutes } from './settings/settings-routing.module';
import { routes as resourcecenterRoutes } from './resourcecenter/resourcecenter-routing.module';
import { routes as administrationRoutes } from './administration/administration-routing.module';
import { routes as reportsRoutes } from './reports/reports-routing.module';
//import { routes as activityRoutes } from './activitylogs/activitylogs-routing.module';
import { AuthGuard } from './core/guards/auth.guard';
import { BreadcrumbsComponent } from './core/breadcrumbs/breadcrumbs.component';

export const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'deployments',
        pathMatch: 'full',
      },
      {
        path: 'cloudservices',
        loadChildren: () => import('./cloud-services/cloud-services.module').then(m => m.CloudServicesModule),
        data: {
          caption: 'Cloud Services',
          children: cloudservicesRoutes,
        },
      },
      {
        path: 'deployments',
        loadChildren: () => import('./deployments/deployments.module').then(m => m.DeploymentsModule),
        data: {
          caption: 'Deployments',
          children: deploymentRoutes,
        },
      },
      {
        path: 'tenants',
        loadChildren: () => import('./tenants/tenants.module').then(m => m.TenantsModule),
        data: {
          caption: 'Tenants',
          children: tenantsRoutes,
        },
      },
      //{
      //  path: 'activitylogs',
      //  loadChildren: () => import('./activitylogs/activitylogs.module').then(m => m.ActivityLogsModule),
      //  data: {
      //    caption: 'Logs',
      //    children: activityRoutes,
      //},
      //},
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        data: {
          caption: 'Account Settings',
          children: settingsRoutes,
        },
      },
      {
        path: 'resourcecenter',
        loadChildren: () => import('./resourcecenter/resourcecenter.module').then(m => m.ResourceCenterModule),
        data: {
          caption: 'Resource Center',
          children: resourcecenterRoutes,
        },
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: {
          caption: 'Reports',
          children: reportsRoutes,
          adminOnly: true,
        },
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        data: {
          caption: 'Administration',
          children: administrationRoutes,
          adminOnly: true,
        },
      },
    ],
  },
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full',
  },
  {
    path: 'reload',
    component: BreadcrumbsComponent,
    pathMatch: 'full',
  },
];
